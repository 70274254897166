import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
  const routes = [
    {
      path: '/',
      component: () => import('../views/Index.vue')
    },
    // {
    //   path: '/index',
    //   name: 'Index',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import('../views/Index.vue')
    // },
    { path: '*', redirect: '/' }
  ]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
